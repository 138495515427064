var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"e-index"},[_c('div',[_c('div',{staticClass:"toggle-class",on:{"click":function($event){return _vm.$router.push({
          path: '/SmartClassroom/RoomList',
          query: { active: 0 },
        })}}},[_c('span',[_vm._v(_vm._s(_vm.roomName))]),_c('van-icon',{attrs:{"name":"arrow-down"}})],1),_vm._m(0),_c('div',{staticClass:"toggle-title"},[_vm._v("场景切换")]),_c('div',{staticClass:"swiper-toggle-page"},[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions,"auto-destroy":true,"delete-instance-on-destroy":true,"cleanup-styles-on-destroy":true,"autoUpdate":true},on:{"ready":_vm.handleSwiperReadied,"click-slide":_vm.handleClickSlide}},_vm._l((_vm.arrScenario),function(item,i){return _c('swiper-slide',{key:i},[_c('div',{staticClass:"toggle-equipment",class:_vm.indexScenario === i ? 'active' : '',on:{"click":function($event){return _vm.toggleScenario(i)}}},[_c('span',[_vm._v(_vm._s(item.Name))])])])}),1)],1),_vm._m(1)]),_c('div',{staticClass:"e-all"},[_c('div',[_c('van-grid',{attrs:{"column-num":2,"gutter":16}},[_vm._l((_vm.list),function(item,index){return [(item.isShow)?_c('van-grid-item',{key:index},[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"l-header"},[_c('span'),_c('span',[_c('van-switch',{attrs:{"active-color":_vm.defineStyle.themeColor,"inactive-color":_vm.defineStyle.color.gray,"size":"18px","loading":""},on:{"click":function($event){return _vm.toggleSwitch(item)}},model:{value:(item.state),callback:function ($$v) {_vm.$set(item, "state", $$v)},expression:"item.state"}})],1)]),_c('div',{staticClass:"l-img"},[_c('router-link',{attrs:{"to":{
                    path: '/SmartClassroom/Equipement',
                    query: { 
                      active: item.index,
                      terType:item.type 
                    },
                  }}},[_c('van-image',{attrs:{"width":"55px","height":"55px","fit":"contain","src":item.img}})],1)],1),_c('div',{staticClass:"l-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"l-subtitle"},[_vm._v(" "+_vm._s(item.subtitle)+_vm._s(item.value)+_vm._s(item.unit)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:((item.value || item.value == 0) && index != 5),expression:"(item.value || item.value == 0) && index != 5"}],staticClass:"l-slider"},[(item.type != 7)?_c('van-slider',{attrs:{"active-color":_vm.defineStyle.themeColor,"bar-height":"5px"},on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e()],1),(index === 5)?_c('div',{staticClass:"voice"},[_c('i',{staticClass:"iconfont icon-control_jian",on:{"click":function($event){item.value <= 0 ? (item.value = 0) : (item.value -= 1)}}}),_c('span',{on:{"touchstart":_vm.recordFunc,"touchend":_vm.recordEndFunc}},[_c('i',{staticClass:"iconfont icon-control04_guangbo",style:({ color: _vm.isRecord ? _vm.defineStyle.themeColor : '' }),on:{"click":function($event){item.value > 99 ? (item.value = 100) : (item.value += 1)}}})]),_c('i',{staticClass:"iconfont icon-control_jia"})]):_vm._e()])]):_vm._e()]}),(_vm.equipment[0].isShow)?_c('van-grid-item',[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"l-header"},[_c('span'),_c('span',[_c('van-switch',{attrs:{"active-color":_vm.defineStyle.themeColor,"inactive-color":_vm.defineStyle.color.gray,"size":"18px","loading":""},on:{"click":function($event){return _vm.toggleSwitch(_vm.equipment[0])}},model:{value:(_vm.equipment[0].state),callback:function ($$v) {_vm.$set(_vm.equipment[0], "state", $$v)},expression:"equipment[0].state"}})],1)]),_c('div',{staticClass:"l-img"},[_c('router-link',{attrs:{"to":{
                  path: '/SmartClassroom/Equipement',
                  query: { active: 3 },
                }}},[_c('van-image',{attrs:{"width":"55px","height":"55px","fit":"contain","src":require('@/assets/自动门.svg')}})],1)],1),_c('div',{staticClass:"l-title"},[_vm._v("自动门")]),_c('div',{staticClass:"l-subtitle",class:{ active: _vm.equipment[0].state }},[_vm._v(" "+_vm._s(_vm.equipment[0].state ? "当前打开状态" : "当前关闭状态")+" ")]),_c('div',{staticClass:"l-slider"})])]):_vm._e(),(_vm.equipment[1].isShow)?_c('van-grid-item',[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"l-header"},[_c('span'),_c('span')]),_c('div',{staticClass:"l-img"},[_c('router-link',{attrs:{"to":{
                  path: '/SmartClassroom/Equipement',
                  query: { active: 4 },
                }}},[_c('van-image',{attrs:{"width":"55px","height":"55px","fit":"contain","src":require('@/assets/室内检测仪器.svg')}})],1)],1),_c('div',{staticClass:"l-title"},[_vm._v("室内检测仪")]),_c('div',{staticClass:"tag"},[_c('div',[_c('i',{staticClass:"iconfont icon-control03_wendu"}),_c('span',[_vm._v(_vm._s(_vm.equipment[1].TEM)+"℃")])]),_c('div',[_c('i',{staticClass:"iconfont icon-control03_shidu"}),_c('span',[_vm._v(_vm._s(_vm.equipment[1].HUM)+"%rh")])])])])]):_vm._e(),(_vm.equipment[2].isShow)?_c('van-grid-item',[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"l-header"},[_c('span'),_c('span')]),_c('div',{staticClass:"l-img"},[_c('router-link',{attrs:{"to":{
                  path: '/SmartClassroom/Equipement',
                  query: { active: 5 },
                }}},[_c('van-image',{attrs:{"width":"55px","height":"55px","fit":"contain","src":require('@/assets/麦克风.svg')}})],1)],1),_c('div',{staticClass:"l-title"},[_vm._v("广播")]),_c('div',{staticClass:"l-subtitle"},[_vm._v("音量"+_vm._s(_vm.equipment[2].value)+"%")]),_c('div',{staticClass:"voice"},[_c('i',{staticClass:"iconfont icon-control_jian",on:{"click":function($event){_vm.equipment[2].value <= 0
                    ? (_vm.equipment[2].value = 0)
                    : _vm.equipment[2].value--}}}),_c('span',{on:{"touchstart":_vm.recordFunc,"touchend":_vm.recordEndFunc}},[_c('i',{staticClass:"iconfont icon-control04_guangbo",style:({ color: _vm.isRecord ? _vm.defineStyle.themeColor : '' })})]),_c('i',{staticClass:"iconfont icon-control_jia",on:{"click":function($event){_vm.equipment[2].value > 99
                    ? (_vm.equipment[2].value = 100)
                    : _vm.equipment[2].value++}}})])])]):_vm._e()],2)],1)]),_c('div',{staticClass:"return-btn",on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('i',{staticClass:"iconfont icon-control04_menu"})]),_c('van-popup',{staticClass:"return-menu",style:({ height: '150px' }),attrs:{"position":"bottom","round":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('van-grid',{attrs:{"clickable":"","column-num":3}},[_c('van-grid-item',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('div',{staticClass:"rm-item"},[_c('div',[_c('i',{staticClass:"iconfont icon-control_arrow"})]),_c('div',[_vm._v("返回上一页")])])]),_c('van-grid-item',{attrs:{"to":"/SmartClassroom/Index"}},[_c('div',{staticClass:"rm-item"},[_c('div',[_c('i',{staticClass:"iconfont icon-shouye_home01"})]),_c('div',[_vm._v("设备首页")])])])],1),_c('div',{staticClass:"popup-close",on:{"click":function($event){_vm.showMenu = false}}},[_vm._v("关闭")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wheather-data"},[_c('div',[_c('i',{staticClass:"iconfont icon-control03_wendu"}),_c('span',[_vm._v("温度26℃")])]),_c('div',[_c('i',{staticClass:"iconfont icon-control03_shidu"}),_c('span',[_vm._v("温度75%rh")])]),_c('div',[_c('i',{staticClass:"iconfont icon-control_yezi"}),_c('span',[_vm._v("空气质量 良")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle-title"},[_c('span',[_vm._v("所有设备")])])}]

export { render, staticRenderFns }