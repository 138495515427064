<template>
  <div class="e-index">
    <div>
      <div @click="
          $router.push({
            path: '/SmartClassroom/RoomList',
            query: { active: 0 },
          })
        "
           class="toggle-class">
        <span>{{ roomName }}</span>
        <van-icon name="arrow-down" />
      </div>
      <div class="wheather-data">
        <div>
          <i class="iconfont icon-control03_wendu"></i>
          <span>温度26℃</span>
        </div>
        <div>
          <i class="iconfont icon-control03_shidu"></i>
          <span>温度75%rh</span>
        </div>
        <div>
          <i class="iconfont icon-control_yezi"></i>
          <span>空气质量 良</span>
        </div>
      </div>
      <div class="toggle-title">场景切换</div>
      <div class="swiper-toggle-page">
        <swiper ref="mySwiper"
                :options="swiperOptions"
                :auto-destroy="true"
                :delete-instance-on-destroy="true"
                :cleanup-styles-on-destroy="true"
                @ready="handleSwiperReadied"
                @click-slide="handleClickSlide"
                :autoUpdate="true">
          <swiper-slide v-for="(item, i) in arrScenario"
                        :key="i">
            <div class="toggle-equipment"
                 :class="indexScenario === i ? 'active' : ''"
                 @click="toggleScenario(i)">
              <span>{{ item.Name }}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="toggle-title">
        <span>所有设备</span>
      </div>
    </div>
    <div class="e-all">
      <div>
        <van-grid :column-num="2"
                  :gutter="16">
          <template v-for="(item, index) in list">
            <van-grid-item v-if="item.isShow"
                           :key="index">
              <div class="list-item">
                <div class="l-header">
                  <span>
                    <!-- <i class="active"></i>在线 -->
                  </span>
                  <span>
                    <van-switch :active-color="defineStyle.themeColor"
                                :inactive-color="defineStyle.color.gray"
                                v-model="item.state"
                                size="18px"
                                loading
                                @click="toggleSwitch(item)">
                      <!-- <template #loading>
                      <van-icon name="fire-o" />
                    </template> -->
                    </van-switch>
                  </span>
                </div>
                <div class="l-img">
                  <router-link :to="{
                      path: '/SmartClassroom/Equipement',
                      query: { 
                        active: item.index,
                        terType:item.type 
                      },
                    }">
                    <van-image width="55px"
                               height="55px"
                               fit="contain"
                               :src="item.img" />
                  </router-link>
                </div>
                <div class="l-title">{{ item.title }}</div>
                <div class="l-subtitle">
                  {{ item.subtitle }}{{ item.value }}{{ item.unit }}
                </div>

                <div class="l-slider"
                     v-show="(item.value || item.value == 0) && index != 5">
                  <van-slider v-if="item.type != 7"
                              :active-color="defineStyle.themeColor"
                              bar-height="5px"
                              v-model="item.value"
                              @change="onChange(item)"></van-slider>
                </div>
                <div class="voice"
                     v-if="index === 5">
                  <i @click="
                      item.value <= 0 ? (item.value = 0) : (item.value -= 1)
                    "
                     class="iconfont icon-control_jian"></i>
                  <span @touchstart="recordFunc"
                        @touchend="recordEndFunc">
                    <i @click="
                        item.value > 99 ? (item.value = 100) : (item.value += 1)
                      "
                       class="iconfont icon-control04_guangbo"
                       :style="{ color: isRecord ? defineStyle.themeColor : '' }"></i>
                  </span>

                  <i class="iconfont icon-control_jia"></i>
                </div>
              </div>
            </van-grid-item>
          </template>

          <van-grid-item v-if="equipment[0].isShow">
            <div class="list-item">
              <div class="l-header">
                <span> </span>
                <span>
                  <van-switch :active-color="defineStyle.themeColor"
                              :inactive-color="defineStyle.color.gray"
                              v-model="equipment[0].state"
                              size="18px"
                              loading
                              @click="toggleSwitch(equipment[0])">
                  </van-switch>
                </span>
              </div>
              <div class="l-img">
                <router-link :to="{
                    path: '/SmartClassroom/Equipement',
                    query: { active: 3 },
                  }">
                  <van-image width="55px"
                             height="55px"
                             fit="contain"
                             :src="require('@/assets/自动门.svg')" />
                </router-link>
              </div>
              <div class="l-title">自动门</div>
              <div class="l-subtitle"
                   :class="{ active: equipment[0].state }">
                {{ equipment[0].state ? "当前打开状态" : "当前关闭状态" }}
              </div>
              <div class="l-slider"></div>
            </div>
          </van-grid-item>
          <van-grid-item v-if="equipment[1].isShow">
            <div class="list-item">
              <div class="l-header">
                <span>
                  <!-- <i></i>在线 -->
                </span>
                <span>
                  <!-- <van-switch :active-color="defineStyle.themeColor"
                              :inactive-color="defineStyle.color.gray"
                              v-model="equipment[1].state"
                              size="18px"
                              loading
                              @click="equipment[1].state = !equipment[1].state">
                  </van-switch> -->
                </span>
              </div>
              <div class="l-img">
                <router-link :to="{
                    path: '/SmartClassroom/Equipement',
                    query: { active: 4 },
                  }">
                  <van-image width="55px"
                             height="55px"
                             fit="contain"
                             :src="require('@/assets/室内检测仪器.svg')" />
                </router-link>
              </div>
              <div class="l-title">室内检测仪</div>
              <div class="tag">
                <div>
                  <i class="iconfont icon-control03_wendu"></i>
                  <span>{{ equipment[1].TEM }}℃</span>
                </div>
                <div>
                  <i class="iconfont icon-control03_shidu"></i>
                  <span>{{ equipment[1].HUM }}%rh</span>
                </div>
              </div>
            </div>
          </van-grid-item>
          <van-grid-item v-if="equipment[2].isShow">
            <div class="list-item">
              <div class="l-header">
                <span>
                  <!-- <i></i>在线 -->
                </span>
                <span></span>
              </div>
              <div class="l-img">
                <router-link :to="{
                    path: '/SmartClassroom/Equipement',
                    query: { active: 5 },
                  }">
                  <van-image width="55px"
                             height="55px"
                             fit="contain"
                             :src="require('@/assets/麦克风.svg')" />
                </router-link>
              </div>
              <div class="l-title">广播</div>
              <div class="l-subtitle">音量{{ equipment[2].value }}%</div>
              <div class="voice">
                <i @click="
                    equipment[2].value <= 0
                      ? (equipment[2].value = 0)
                      : equipment[2].value--
                  "
                   class="iconfont icon-control_jian"></i>

                <span @touchstart="recordFunc"
                      @touchend="recordEndFunc">
                  <i class="iconfont icon-control04_guangbo"
                     :style="{ color: isRecord ? defineStyle.themeColor : '' }"></i>
                </span>

                <i @click="
                    equipment[2].value > 99
                      ? (equipment[2].value = 100)
                      : equipment[2].value++
                  "
                   class="iconfont icon-control_jia"></i>
              </div>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div @click="showMenu = !showMenu"
         class="return-btn">
      <i class="iconfont icon-control04_menu"></i>
    </div>
    <van-popup v-model="showMenu"
               position="bottom"
               round
               class="return-menu"
               :style="{ height: '150px' }">
      <van-grid clickable
                :column-num="3">
        <van-grid-item @click="$router.go(-1)">
          <div class="rm-item">
            <div><i class="iconfont icon-control_arrow"></i></div>
            <div>返回上一页</div>
          </div>
        </van-grid-item>
        <van-grid-item to="/SmartClassroom/Index">
          <div class="rm-item">
            <div><i class="iconfont icon-shouye_home01"></i></div>
            <div>设备首页</div>
          </div>
        </van-grid-item>
      </van-grid>
      <div @click="showMenu = false"
           class="popup-close">关闭</div>
    </van-popup>
  </div>
</template>
<script>
import { Image as VanImage, Grid, GridItem, Switch, Slider, Popup } from "vant";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import {
  getScenarioAll,
  getRoomEquipmentAll,
  getScenarioDetail,
  getDefaultRoom,
} from "@/api/TerminalEquipment/RoomEquipment.js";

import "swiper/swiper-bundle.css";
import defineMethods from "@/methods";
export default {
  components: {
    Swiper,
    SwiperSlide,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Switch.name]: Switch,
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
    [Popup.name]: Popup,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data () {
    return {
      roomId: 0, //房间ID
      roomName: "", //房间名称
      showMenu: false, //悬浮按钮,底部弹出层状态
      chooseIndex: 0,
      isRecord: true, //广播点击状态
      checked: false,
      swiperOptions: {
        slidesPerView: 3.1,
        slidesPerGroup: 3,
        spaceBetween: 0,
        observer: true,
      },
      typeStr: [],//设备类型集合
      indexScenario: -1, //场景下标
      arrScenario: [], //所有场景
      terminalCmd: [], //指令
      list: [
        {
          index: 0,
          type: 1,
          title: "智能照明灯",
          subtitle: "亮度",
          img: require("@/assets/灯泡.svg"),
          value: 50,
          state: true,
          unit: "%",
          isShow: false,
        },
        {
          index: 1,
          type: 2,
          title: "空调",
          subtitle: "温度",
          img: require("@/assets/空调.svg"),
          value: 50,
          state: true,
          unit: "℃",
          isShow: false,
        },
        {
          index: 2,
          type: 3,
          title: "窗帘",
          subtitle: "打开",
          img: require("@/assets/窗帘.svg"),
          value: 0,
          oldValue: 0,
          state: false,
          unit: "%",
          isShow: false,
        },
        {
          index: 2,
          type: 7,
          title: "窗帘",
          subtitle: "打开",
          img: require("@/assets/窗帘.svg"),
          value: 100,
          oldValue: 0,
          state: true,
          unit: "%",
          isShow: false,
        },
      ],
      equipment: [
        {
          type: 4,
          title: "自动门",
          // subtitle: "当前关闭状态",
          img: require("@/assets/自动门.svg"),
          state: false,
          isShow: false,
          TerminalCode: [],
        },
        {
          type: 5,
          title: "室内检测仪",
          img: require("@/assets/室内检测仪器.svg"),
          state: true,
          isShow: false,
          TEM: 0,
          HUM: 0,
        },
        {
          type: 6,
          title: "广播",
          subtitle: "音量",
          img: require("@/assets/麦克风.svg"),
          value: 50,
          unit: "%",
          isShow: false,
        },
      ],
    };
  },
  methods: {
    handleSwiperReadied () { },
    handleClickSlide () {
    },
    //加载所有场景
    async loadScenarioAll () {
      let list = await getScenarioAll(this.roomId);
      this.arrScenario = list.Data;
    },
    //切换场景,更新设备信息, sId-场景值,默认为0:当前教室设备状态
    async toggleScenario (i) {
      this.indexScenario = i;
      let items = this.arrScenario[i];
      let res = await getScenarioDetail(items.id, this.typeStr.substr(1));
      res.Data.forEach((a) => {
        this.scenarioSettingEquipment(a.TerminaId, a.TerminalType, a.CmdJson);
      });
    },
    //广播-按住
    recordFunc ($event) {
      this.isRecord = true;
    },
    //广播-松开
    recordEndFunc () {
      this.isRecord = false;
    },
    //设备开关
    toggleSwitch (item) {
      item.state = !item.state;
      item.value > 0 && item.state === false
        ? (item.value = 0)
        : (item.value = 100);

      this.indexScenario = -2;

      if (item.type == 3) {
        let levType = this.list[2].oldValue > item.value ? 0 : 1;
        this.sendCmdJson(item.type, item.value, levType);
      }
      else if (item.type == 4) {
        this.men_SendCmdJson();
      }
      else if (item.type == 7)
        this.cl_SendCmdJson_7(item.value);
      else {
        this.sendCmdJson(item.type, item.value);
      }
    },
    //设备属性值改变
    onChange (item) {
      item.value <= 0 ? (item.state = false) : (item.state = true);
      this.indexScenario = -2;

      if (item.type == 3) {
        let levType = this.list[2].oldValue > item.value ? 0 : 1;
        this.sendCmdJson(item.type, item.value, levType);
      } else {
        this.sendCmdJson(item.type, item.value);
      }
    },
    //加载房间设备
    async loadRoomEquipment (roomId) {
      this.typeStr = "";
      var res = await getRoomEquipmentAll(roomId);
      this.equipmentAll = res.Data;
      res.Data.forEach((element) => {
        this.typeStr += "," + element.TerminalType;
        let gatewayCode = [],
          terminalCode = [],
          sttributeJsonList = [];
        element.TerminalList.forEach((tInfo) => {
          gatewayCode.push(tInfo.GatewayCode);
          terminalCode.push(tInfo.TerminalCode);
          sttributeJsonList.push(tInfo.SttributeJson);
        });

        if (element.TerminalCmd != null) {
          element.TerminalCmd.forEach((cmdInfo) => {
            cmdInfo.gatewayCode = gatewayCode;
            cmdInfo.terminalCode = terminalCode;
            cmdInfo.sttributeJsonList = sttributeJsonList;
            this.terminalCmd.push(cmdInfo);
          });
        }
        this.showEquipment({
          terminalType: element.TerminalType,
          terminalCode: element.TerminalList[0].TerminalCode,
          sttributeJson: {
            attributes: JSON.parse(element.TerminalList[0].SttributeJson)
          }
        });
      });
    },
    //设置设备值
    showEquipment (terminalDetail) {
      let obj = terminalDetail.sttributeJson.attributes;
      switch (terminalDetail.terminalType) {
        case 1:
          this.list[0].isShow = true;
          this.list[0].state = obj.SWI == "ON" ? true : false;
          this.list[0].value = obj.SWI == "ON" ? parseInt(obj.LEV) : 0;
          localStorage.LightNum = parseInt(obj.LEV);
          break;
        case 2:
          this.list[1].isShow = true;
          this.list[1].state = obj.SWI == "ON" ? true : false;
          this.list[1].value = obj.SWI == "ON" ? parseInt(obj.LEV) : 0;
          break;
        case 3:
          this.list[2].isShow = true;
          this.list[2].state = obj.WIN == "OPEN" ? true : false;
          this.list[2].value = obj.WIN == "OPEN" ? parseInt(obj.LEV) : 0;
          this.list[2].oldValue = this.list[2].value;
          localStorage.CurtainsNum = parseInt(obj.LEV);

          break;
        case 7:
          this.list[3].isShow = true;
          if (obj.LEV != null) {
            this.list[3].value = parseInt(obj.LEV);
            this.list[3].state = parseInt(obj.LEV) > 0 ? true : false;
            this.list[3].subtitle = parseInt(obj.LEV) > 0 ? '打开' : '关闭';
            localStorage.CurtainsNum = parseInt(obj.LEV);
          }
          break;
        case 4:
          this.equipment[0].isShow = true;
          if (obj != null) {
            this.equipment[0].TerminalCode.push(terminalDetail.terminalCode);
            this.equipment[0].state = obj.SWITCH == "OPEN" ? true : false;
          }
          break;
        case 5:
          this.equipment[1].isShow = true;
          this.equipment[1].TEM = obj.TEM;
          this.equipment[1].HUM = obj.HUM;
          break;
        case 6:
          this.equipment[2].isShow = true;
          this.equipment[2].value = parseInt(obj.LEV);
          break;
        default:
          break;
      }
    },
    //场景模式设备值
    scenarioSettingEquipment (terminalId, terminalType, sttributeJson) {

      let jsonInfo = JSON.parse(sttributeJson);
      if (terminalId == -1) {
        //加载全部设备
        this.equipmentAll.forEach(q => {
          if (q.TerminalType == terminalType) {
            let terCodes = [], gatewayCode = '';
            switch (terminalType) {
              case 4:
                this.equipment[0].state = jsonInfo.Msg == '2' ? true : false;
                this.men_SendCmdJson();
                break;
              case 7:
                this.cl_SendCmdJson_7(jsonInfo.attributes.LEV);
                break;
              default:
                q.TerminalList.forEach(a => {
                  gatewayCode = a.GatewayCode;
                  terCodes.push(a.TerminalCode);
                });
                jsonInfo.sourceId = gatewayCode;
                jsonInfo.id = terCodes;
                defineMethods.openBatchTerminalCmd(JSON.stringify(jsonInfo));
                break;
            }
            this.showEquipment({
              terminalType: q.TerminalType,
              terminalCode: q.TerminalList[0].TerminalCode,
              sttributeJson: jsonInfo
            });
          }
        });
      }
    },
    //发送设备指令
    sendCmdJson (terType, lev, levType) {
      if (this.indexScenario == -1) return false;
      let terCmd = this.getCmdParse(terType, lev > 0 ? 2 : 1);
      if (terCmd == null) return false;
      defineMethods.openTerminalCmd(
        terCmd.CommandJson,
        terType,
        terCmd.gatewayCode[0],
        terCmd.terminalCode.toString(),
        lev,
        levType
      );
      if (terType == 3)
        this.list[2].oldValue = lev;
    },
    //窗帘发送设备指令
    cl_SendCmdJson_7 (lev) {
      if (this.indexScenario == -1) return false;
      let terCmd = this.getCmdParse(7, 1);
      if (terCmd == null) return false;
      for (let index = 0; index < terCmd.sttributeJsonList.length; index++) {
        const element = JSON.parse(terCmd.sttributeJsonList[index]);
        if (index == 0) {
          defineMethods.openTerminalCmd(
            terCmd.CommandJson,
            7,
            terCmd.gatewayCode[index],
            '00124B0021269DEA',
            lev > 0 ? element.OPEN : element.COLSE
          );
        } else {
          setTimeout(() => {
            defineMethods.openTerminalCmd(
              terCmd.CommandJson,
              7,
              terCmd.gatewayCode[index],
              '00124B0021269DEA',
              lev > 0 ? element.OPEN : element.COLSE
            );
          }, index * 1300);
        }

      }

    },
    //门禁发送设备指令
    men_SendCmdJson () {
      this.equipment[0].TerminalCode.forEach(q => {
        let obj = {
          ServersCode: q,
          Msg: this.equipment[0].state ? "2" : "0",
          Command: 5,
        };
        console.log(obj);
        this.global.wsSendMsg('door', JSON.stringify(obj));
      });
    },
    //获取设备指令
    getCmdParse (terminaType, cmdType) {
      let info = null;
      this.terminalCmd.forEach((a) => {
        if (a.CommandType == cmdType && a.TerminalType == terminaType) {
          info = a;
        }
      });
      return info;
    },
  },
  async mounted () {
    if (this.$route.query.roomId == -1) {
      let res = await getDefaultRoom();
      this.roomId = res.Data.id;
      this.roomName = res.Data.RoomNmae;
    } else {
      let info = JSON.parse(localStorage.RoomInfo);
      this.roomId = info.RoomId;
      this.roomName = info.RoomNmae;
    }
    this.loadScenarioAll();
    this.loadRoomEquipment(this.roomId);
  },
};
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';

.e-index {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background: $bgColor;
	position: relative;
	.e-all {
		width: 100%;
		padding-bottom: 20px;
		.van-grid-item__content {
			box-shadow: 0px 0px 10px #ebebeb;
			opacity: 1;
			border-radius: 5px;
		}
	}
	> div:first-child {
		padding-bottom: 12px;
		background: linear-gradient(180deg, themeRGBColor(0.2) 0%, themeRGBColor(0) 100%);
	}
	.list-item {
		width: 100%;
		padding-bottom: 20px;
		box-sizing: border-box;
		text-align: center;
		// padding: 10px;
		.l-header {
			height: 20px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			> span {
				display: flex;
				flex-flow: row nowrap;
				justify-content: center;
				align-items: center;
			}
			i {
				display: inline-block;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				box-shadow: 0px 0px 6px #c8c8c8;
				background: #c8c8c8;
				margin-right: 5px;
			}
			i.active {
				background: #fd9232;
				box-shadow: 0px 0px 6px #fd9232;
			}
		}
		.l-img {
			text-align: center;
			margin-top: 5px;
		}
		.l-title {
			font-size: 16px;
			font-weight: 400;
			color: $titleColor;
			text-align: center;
			margin-bottom: 3px;
		}
		.l-subtitle {
			font-size: 12px;
			font-weight: 400;
			color: #949494;
			transform: scale(0.95);
		}
		.l-subtitle.active {
			color: $themeColor;
		}
		.l-slider {
			height: 20px;
			width: 80%;
			margin: 0 auto;
			margin-top: 22px;
		}
		.tag {
			width: 100%;
			// height: 40px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-content: center;
			margin: 10px 0;
			> div {
				display: inline-block;
				padding: 5px 6px;
				// margin-right: 3px;
				background: themeRGBColor(0.1);
				border-radius: 5px;
				color: $themeColor;
				font-size: 12px;
				transform: scale(0.83);
				text-align: center;
				margin-right: -3px;
				// margin-bottom: 3px;
			}
		}
		.voice {
			width: 60%;
			margin: 0 auto;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			margin-top: 10px;
			i {
				color: $gray;
			}
			i:active {
				color: $themeColor;
			}
			> span {
				> i {
					font-size: 32px;
				}
			}
		}
	}
	.toggle-title {
		margin-bottom: 12px;
		margin-left: 24px;
		font-size: 16px;
		font-weight: 400;
		color: $titleColor;
	}
	.toggle-title:last-child {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-right: 18px;
		margin-top: 20px;
	}
	.toggle-class {
		font-size: 16px;
		font-weight: bold;
		color: #2a2a2a;
		margin-bottom: 9px;
		margin-left: 24px;
		padding-top: 16px;
	}
	.wheather-data {
		display: flex;
		flex-flow: row nowrap;
		margin-left: 24px;
		margin-bottom: 26px;
		> div {
			position: relative;
			margin-right: 16px;

			font-size: 12px;
			font-weight: 400;
			color: #949494;
		}
		> div:not(:last-child):before {
			content: '';
			position: absolute;
			top: 50%;
			right: -8px;
			height: 10px;
			width: 1px;
			transform: translateY(-50%);
			background: $gray;
		}
	}
	.swiper-container {
		overflow: visible;
	}
	.swiper-toggle-page {
		margin-left: 16px;
	}
	.toggle-equipment.active {
		background: $themeColor;
		color: #fff;
	}
	.toggle-equipment {
		width: 107px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.5);
		border-radius: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #2a2a2a;
		background: #fff;
		i {
			margin-right: 7px;
		}
	}
	.return-btn {
		position: fixed;
		top: 543px;
		right: 10px;
		width: 40px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		background: #ffffff;
		box-shadow: 0px 0px 6px #c8c8c8;
		border-radius: 50%;
		color: #949494;
		i {
			font-size: 24px;
		}
	}
	.return-menu {
		.rm-item {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 80px;
			font-size: 12px;
			font-weight: 400;
			color: $titleColor;
			i {
				font-size: 28px;
			}
			> div:first-child {
				margin-bottom: 5px;
			}
		}
		margin-top: 20px;
		.popup-close {
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
			border-top: 1px solid #ebedf0;
		}
	}
}
</style> 
<style lang="scss">
.e-index {
	.van-grid-item__content::after {
		border-width: 0;
	}
}
</style>